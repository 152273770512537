import { addMultipleEventListener, debounce, getFocusableElements, setClonedHeaderHeight, setHeaderHeight, throttle } from './helpers';
import DOMAnimations from '@antipodes-medical/dom-animations/DOMAnimations';

export default class Header {

	/**
	 * Init the header
	 */
	static init(isClonedHeader = false) {
		this.isClonedHeader = isClonedHeader;
		if (!this.isClonedHeader) {
			this._handleResponsiveMenu();
			this._handleSearchBar();
			this._setHeaderHeight();
		} else {
			this.$parentHeader = null;
			this.$dropdownTogglers = null;
			this.$header = null;
			this._handleSearchBar();
			this._setHeaderHeight();
		}
	}

	/**
	 * Get parent header
	 *
	 * @returns {Element}
	 * @private
	 */
	static _getParentHeader() {
		if (!this.$parentHeader) {
			if (!this.isClonedHeader) {
				this.$parentHeader = document.querySelector('header:not(.clone) .header__bottom__menu > nav > ul');
			} else {
				this.$parentHeader = document.querySelector('header.clone .header__bottom__menu > nav > ul');
			}
		}
		return this.$parentHeader;
	}

	/**
	 * Get dropdown togglers
	 *
	 * @returns {NodeListOf<Element>}
	 * @private
	 */
	static _getDropdownTogglers() {
		if (!this.$dropdownTogglers) {
			this.$dropdownTogglers = this._getParentHeader().querySelectorAll('.menu-item-has-children');
		}
		return this.$dropdownTogglers;
	}

	/**
	 * Get header
	 *
	 * @returns {Element}
	 * @private
	 */
	static _getHeader() {
		if (!this.$header) {
			if (!this.isClonedHeader) {
				this.$header = document.querySelector('.header:not(.clone)');
			} else {
				this.$header = document.querySelector('.header.clone');
			}
		}
		return this.$header;
	}

	/**
	 * Set header height in a css variable
	 *
	 * @private
	 */
	static _setHeaderHeight() {
		if (!this.isClonedHeader) {
			addMultipleEventListener(window, ['load', 'resize'], debounce(setHeaderHeight, 100));
		} else {
			addMultipleEventListener(window, ['load', 'resize'], debounce(setClonedHeaderHeight, 100));
		}
	}

	/**
	 * Responsive menu
	 *
	 * @private
	 */
	static _handleResponsiveMenu(activeMenuClass = 'responsiveMenuIsOpen') {
		const $hamburger = document.querySelector('.button-menu-mobile');
		const activeClass = 'isActive';
		const $responsiveMenu = document.querySelector('.header__responsiveMenu');
		const animationTime = 500;
		const $overlay = document.querySelector('.responsiveMenuOverlay');

		/**
		 * Open the responsive menu
		 */
		const open = () => {
			$responsiveMenu.querySelectorAll(getFocusableElements()).forEach($focusableElement => $focusableElement.removeAttribute('tabindex'));
			document.body.classList.add(activeMenuClass);
			$hamburger.classList.add('is-active');
			$hamburger.setAttribute('aria-expanded', 'true');
			$responsiveMenu.setAttribute('aria-hidden', 'true');
		};

		/**
		 * Close the responsive menu
		 */
		const close = () => {
			$responsiveMenu.querySelectorAll(getFocusableElements()).forEach($focusableElement => $focusableElement.setAttribute('tabindex', '-1'));
			document.body.classList.remove(activeMenuClass);
			$hamburger.classList.remove('is-active');
			$hamburger.setAttribute('aria-expanded', 'false');
			$responsiveMenu.setAttribute('aria-hidden', 'false');
		};

		/**
		 * Toggle responsive menu, open or close
		 */
		const toggleResponsiveMenu = () => {
			if (document.body.classList.contains(activeMenuClass)) {
				close();
			} else {
				open();
			}
		};

		if ($overlay) {
			$overlay.addEventListener('click', () => {
				close();
			});
		}

		$responsiveMenu.querySelectorAll(getFocusableElements()).forEach($focusableElement => $focusableElement.setAttribute('tabindex', '-1'));

		addMultipleEventListener(window, ['load', 'resize'], debounce(() => {
			if (window.getComputedStyle($hamburger).display === 'none') {
				$hamburger.setAttribute('disabled', '');
			} else {
				$hamburger.removeAttribute('disabled');
			}
		}, 300));

		addMultipleEventListener($hamburger, ['click', 'keydown'], e => {
			if (e.type === 'keydown' && (
			    e.keyCode !== 13 && e.keyCode !== 32
			)) {
				return;
			}

			e.preventDefault();
			if (document.body.classList.contains(activeMenuClass)) {
				$responsiveMenu.querySelectorAll(getFocusableElements()).forEach($focusableElement => $focusableElement.setAttribute('tabindex', '-1'));
				document.body.classList.remove(activeMenuClass);
				$hamburger.classList.remove('is-active');
				$hamburger.setAttribute('aria-expanded', 'false');
				$responsiveMenu.setAttribute('aria-hidden', 'false');
			} else {
				$responsiveMenu.querySelectorAll(getFocusableElements()).forEach($focusableElement => $focusableElement.removeAttribute('tabindex'));
				document.body.classList.add(activeMenuClass);
				$hamburger.classList.add('is-active');
				$hamburger.setAttribute('aria-expanded', 'true');
				$responsiveMenu.setAttribute('aria-hidden', 'true');
			}
		});

		$responsiveMenu.querySelectorAll('li.menu-item-has-children').forEach($menuItemHaveDropdownMenu => {
			const $button = $menuItemHaveDropdownMenu.querySelector('.dropdown-toggle');
			const $subMenu = $menuItemHaveDropdownMenu.querySelector('.nav-drop');

			[
				$menuItemHaveDropdownMenu.querySelector('a'),
				$menuItemHaveDropdownMenu.querySelector('.nav-drop')
			].forEach($item => $item.addEventListener('click', e => e.stopPropagation()));

			$menuItemHaveDropdownMenu.addEventListener('click', throttle((e) => {
				e.preventDefault();

				if ($menuItemHaveDropdownMenu.classList.contains(activeClass)) {
					$button.setAttribute('aria-expanded', 'false');
					$subMenu.setAttribute('aria-hidden', 'true');
					$menuItemHaveDropdownMenu.classList.remove(activeClass);
					DOMAnimations.slideUp($subMenu, animationTime);
				} else {
					$button.setAttribute('aria-expanded', 'true');
					$subMenu.setAttribute('aria-hidden', 'false');
					$menuItemHaveDropdownMenu.classList.add(activeClass);
					DOMAnimations.slideDown($subMenu, animationTime);
				}
			}, animationTime));
		});
	}

	/**
	 * Handle search bar
	 *
	 * @private
	 */
	static _handleSearchBar(searchBarOpenedClass = 'searchBarIsActive') {
		const $searchBarOverlay = document.querySelector('.searchBarOverlay');
		let $togglers, $searchBar, $focusableElements;

		if (!this.isClonedHeader) {
			$togglers = [
				'header:not(.clone) [data-search-button]'
			];
			$searchBar = document.querySelector('.header__searchBar');
			$focusableElements = $searchBar.querySelectorAll(getFocusableElements());
		} else {
			$togglers = [
				'header.clone [data-search-button]'
			];
			$searchBar = document.querySelector('header.clone .header__searchBar');
			$focusableElements = $searchBar.querySelectorAll(getFocusableElements());
		}

		/**
		 * Close search bar
		 *
		 * Accessibility
		 *
		 * @param trigger
		 */
		const closeSearchBar = trigger => {
			document.body.classList.remove(searchBarOpenedClass);
			$searchBar.setAttribute('aria-hidden', 'true');
			$searchBar.setAttribute('tabindex', '-1');
			$focusableElements.forEach($focusableElement => $focusableElement.setAttribute('tabindex', '-1'));

			if (trigger) {
				setTimeout(() => trigger.focus(), 10);
			}
		};

		/**
		 * Open search bar
		 *
		 * - Accessibility
		 *
		 * @param trigger
		 */
		const openSearchBar = trigger => {
			document.body.classList.add(searchBarOpenedClass);
			$searchBar.setAttribute('aria-hidden', 'false');
			$searchBar.setAttribute('tabindex', '0');
			$focusableElements.forEach($focusableElement => $focusableElement.setAttribute('tabindex', '0'));

			// Escape key should close the search bar
			$searchBar.addEventListener('keydown', e => e.key === 'Escape' ? closeSearchBar(trigger) : null);

			// Close button should close the search bar
			addMultipleEventListener($searchBar.querySelector('.searchForm__close'), ['click', 'keydown'], e => {
				if (e.type === 'click' || (
					e.type === 'keydown' && e.key === 'Enter'
				)) {
					e.preventDefault();
					closeSearchBar(trigger);
				}
			});

			// Trap the focus inside
			const firstFocusableElement = $focusableElements[0];
			const lastFocusableElement = $focusableElements[$focusableElements.length - 1];

			if (!firstFocusableElement) {
				return;
			}
			window.setTimeout(() => {
				firstFocusableElement.focus();

				// Trapping focus inside the dialog
				$focusableElements.forEach(focusableElement => {
					if (focusableElement.addEventListener) {
						focusableElement.addEventListener('keydown', e => {
							const tab = e.key === 'Tab';
							if (!tab) {
								return;
							}

							if (e.shiftKey) {
								if (e.target === firstFocusableElement) { // Shift + Tab
									e.preventDefault();
									lastFocusableElement.focus();
								}
							} else if (e.target === lastFocusableElement) { // Tab
								e.preventDefault();
								firstFocusableElement.focus();
							}
						});
					}
				});
			}, 100);
		};

		// Element inside the search bar shouldn't be tabbable by default
		$focusableElements.forEach($focusableElement => $focusableElement.setAttribute('tabindex', '-1'));

		// Open search bar
		document.querySelectorAll($togglers).forEach($toggler => {
			$toggler.addEventListener('click', e => {
				e.preventDefault();
				if (document.body.classList.contains(searchBarOpenedClass)) {
					closeSearchBar($toggler);
				} else {
					openSearchBar($toggler);
				}
			});
		});

		if ($searchBarOverlay) {
			// Close search bar on backdrop click
			$searchBarOverlay.addEventListener('click', e => {
				e.preventDefault();
				closeSearchBar();
			});
		}
	}

}