import Header from './Header';

Header.init();

const $lang = document.querySelector('.lang_current');
const $listLang = document.querySelector('.list_lang');
if ($lang && $listLang) {
	$lang.addEventListener('click', e => {
		e.preventDefault();
		$listLang.classList.toggle('is-active');
		return false;
	});
}